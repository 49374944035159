/*
 * 업무구분: 고객
 * 화 면 명: MSPCM322M
 * 화면설명: 수금인수고객
 * 작 성 일: 2022.07.19
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container title="수금인수고객" :show-title="true" type="subpage" action-type="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM116P()" @on-scroll-bottom="fn_LoadingData()"
   :topButton="true" ref="page" @on-scroll="fn_OnPageScrollEvent">
    <!-- 전체체크 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="checkDisabled || isEmptyView" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">{{ checkCount > 0 ? checkCount + '명 선택' : '총 ' + totCnt + '명' }}</span>
              <!-- 고객카드 없음 240117 추가 -->
              <span class="need-cs-card guide mt-2">고객카드없음</span>
              <!-- 고객카드 없음 240117 추가 -->
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <!-- 조회결과 리스트 -->
        <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-accodian ns-style2">
          <mo-list :list-data="cmUndtkCustCmpxSVO">
            <template #list-item="{ index, item }">
              <mo-list-item expandable prevent-expand :ref="item.contNo" :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <!-- 체크박스 -->
                <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount(item)" :disabled="checkDisabled" :ref="`chk${index}`"/>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <!-- 고객명 -->
                      <!-- 고객카드 없음 240117 추가  -->
                      <!-- 클래스 maxW240 240129 추가(해당 화면 style에 클래스 등록)  -->
                      <span class="maxW240" :class="item.chnlCustId.trim() === '' ? 'need-cs-card' : ''" @click.stop="fn_OpenCustInfoBs(item)">
                        {{ item.custNm }}
                      </span>
                      <!-- 고객카드 없음 240117 추가 -->
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box">
                      <!-- 생년월일 -->
                      <span>{{ $commonUtil.dateDashFormat(item.dob) }}</span><em>|</em>
                      <!-- 성별 -->
                      <span>{{ !$bizUtil.isEmpty(item.sxdsCdNm) ? (item.sxdsCdNm === '남자' ? '남' : '여') : '' }}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box">
                      <!-- 계약번호 -->
                      <span>계약번호</span>
                      <!-- 24년 1월 반영 예정 -->
                      <span class="ml20 underline" @click.stop="fn_MoveMSPPM931M(item)">{{ item.contNo }}</span>
                    </div>
                  </div>
                </div>
                <template v-slot:expand class="ns-gray-box">
                  <div class="list-item-detail customer-interest">
                    <div class="contents-row">
                      <div class="title-cell w95">
                        <span>수금인수일</span>
                      </div>
                      <div class="value-cell">
                        <span>{{ $commonUtil.dateDashFormat(item.undtkYmd) }}</span>
                      </div>
                    </div>
                    <div class="contents-row">
                      <div class="title-cell w95">
                        <span>최근활동일</span>
                      </div>
                      <div class="value-cell">
                        <span>{{ $commonUtil.dateDashFormat(item.rntTuchYmd) }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <!-- NoData 영역  -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>

        <!-- Toast -->
        <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
        <div v-if="cmUndtkCustCmpxSVO.length > 0" class="ns-height40"></div>

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPCM116P from '@/ui/cm/MSPCM116P' // 수금인수고객 목록 검색
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM322M',
  // 현재 화면 ID
  screenId: 'MSPCM322M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MspBottomSelect,
    MSPCM116P
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      pChnlCustId: String,
      pCnsltNo: String,

      isMngr: false, // 지점장 여부
      toastMsg: '', // 문자발송 toast 문구
      pOrgData322M: {}, // 최근 조직 조회 데이터

      // 체크 변수
      checkDisabled: false, // 체크박스 비활성화 여부
      isOneOrMoreCheck: false, // 전체선택

      // 팝업 연동
      popup116: {}, // MSPCM116P 전체고객 목록 검색
      
      // 조회 변수
      cmUndtkCustCmpxSVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      lv_cnsltNo: '', // 컨설턴트 번호
      lv_inputParam: { // 서비스호출 Obj
        hofOrgNo: '', // 사업부조직번호
        dofOrgNo: '', // 지역단조직번호
        fofOrgNo: '', // 지점조직번호
        cnsltNo: '', // 컨설턴트번호
        inqrScCd: '', // 조회구분코드
        custScCd: '' // 고객구분코드
      },
      totCnt: 0, // 누적 총 조회건수
      undtkRsnScCdKey: '0', // 수금인수 구분 선택값(default 전체)
      inputCustNm: '', // 고객명 입력값
      nowDate: moment(new Date()).format('YYYY-MM-DD'), // 오늘
      startDate: this.$bizUtil.getFirstDayOfMonth(new Date(moment(this.nowDate).add(-6, 'months')), 'String'), // 시작일 : 6개월전 (2023.08.01 3개월>6개월로 변경)
      endDate: this.$bizUtil.getLastDayOfMonth(new Date(moment(new Date()).format('YYYY-MM-DD')), 'String'), // 종료일 : 당월
      pObjmsg: { // 고객카드 생성 Obj
        cnsltNo: '', // 컨설턴트번호(ZA_CHNL_ID)
        custId: '', // 고객ID(PARTNER)
        custNm: '', // 고객명
        knb: '', // 주민번호
        birthDay: '', // 생년월일
        gender: '' // 성별
      },

      // 더보기 key
      stndKeyList: [],
      stndKeyVal: 'start',
      pageRowCnt: '20', // 페이지 카운트

      isSearched: false, // 조회이력 저장 변수

      crntScrollPosition: 0, // KeppAlive 스크롤 위치 변수
      pageScrollId: 'scroll_MSPCM322M', // 화면 Scroll 영역 ID
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.cmUndtkCustCmpxSVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cmUndtkCustCmpxSVO.filter(item => item.checked).length
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      // 고객카드가 없는 사람을 제외하고 카운트
      let cnt = this.cmUndtkCustCmpxSVO.filter(item => item.chnlCustId.trim() !== '').length
      
      if (this.checkCount === cnt && this.selectItems.filter(item => item.chnlCustId.trim() === '').length === 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호
    this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo // 지역단조직번호
    this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
    this.lv_inputParam.HofOrgNo = this.userInfo.onpstHofOrgNo // 소속사업부조직번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 공통코드 조회
    this.fn_CommCdList()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  activated () {
    const lv_Vm = this

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // post 호출 전 Progress 타입 설정.
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

    if(this.pageScrollId){
      // 스크롤 이동 처리
      setTimeout(()=>{
        document.getElementById(lv_Vm.pageScrollId).scrollTop = lv_Vm.crntScrollPosition        
      }, 500);
    }
  },

  deactivated () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },
      
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData322M = {} // 조직 조회 데이터
          this.inputCustNm = '' // 고객명
          this.undtkRsnScCdKey = '0' // 인수구분
          this.startDate = this.$bizUtil.getFirstDayOfMonth(new Date(moment(this.nowDate).add(-6, 'months')), 'String') // 조회시작일
          this.endDate = this.$bizUtil.getLastDayOfMonth(new Date(moment(new Date()).format('YYYY-MM-DD')), 'String') // 조회종료일
  
          this.isSearched = false // 검색이력 초기화
  
          // 재조회
          this.fn_Submit()
        } else {
          // 스크롤 초기화
          this.crntScrollPosition = 0

          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_CommCdList
     * 설명       : 공통코드 조회
     ******************************************************************************/
    fn_CommCdList () {
      const lv_Vm = this

      lv_Vm.$commonUtil.selListSliCommCd('UNDTK_RSN_SC_CD').then(function () {
        let codeList = lv_Vm.getStore('code').getters.getState.get('UNDTK_RSN_SC_CD')

        if (codeList !== undefined && codeList !== null && codeList.length !== 0) {
          // 수금인수고객 조회
          lv_Vm.fn_Submit()
        } else {
          lv_Vm.getStore('toast').dispatch('ADD', codeList?.msgComm?.msgDesc)
        }
      })

    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData322M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData322M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      this.totCnt = 0
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [] // 초기로드 or 조회버튼시
      this.cmUndtkCustCmpxSVO = [] // 인피니트 바인딩 데이터
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.fn_DataSet() // 조회 파라미터 세팅

      // post 호출 전 Progress 타입 설정.
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.fn_GetClmnyUndtkCustList() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      // 지점장 조회시 구분
      if (this.pOrgData322M.cnslt !== undefined && this.isMngr === true) {
        this.lv_inputParam.cnsltNo  = this.pOrgData322M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        this.lv_inputParam.cnsltNo  = this.lv_cnsltNo // 컨설턴트 번호
      }
      this.lv_inputParam.undtkRsnScCd = this.undtkRsnScCdKey // 수금인수 구분코드
      this.lv_inputParam.custNm = this.inputCustNm // 고객명
      this.lv_inputParam.strYm = this.startDate.replace(/-/gi, '').substring(0, 6) // 조회시작일(수금인수년월)
      this.lv_inputParam.endYm = this.$bizUtil.getLastDayOfMonth(new Date(this.endDate), 'String').replace(/-/gi, '').substring(0, 6) // 조회종료일(수금인수년월)
    },

    /******************************************************************************
     * Function명 : fn_GetClmnyUndtkCustList
     * 설명       : 서비스 호출(수금인수고객 목록 조회)
     ******************************************************************************/
    fn_GetClmnyUndtkCustList () {
      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM55S1'// 수금인수고객 목록 조회
      const trnstId = 'txTSSCM47S5'// 수금인수고객 목록 조회
      const auth = 'S'
      const lv_Vm = this
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body && response.msgComm && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.iCUndtkCustMoblVO

            if (responseList !== null) {
              // 리스트 체크변수 적용
              for (let i = 0; i < responseList.length; i++) {
                lv_Vm.$set(responseList[i], 'checked', false)
                lv_Vm.$set(responseList[i], 'openedDetail', false)
                lv_Vm.$set(responseList[i], 'cnsltNo', response.body.cnsltNo)
              }

              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyVal = 'end'
              } else {
              // 다음페이지 데이터 존재함
                lv_Vm.stndKeyVal = 'more'
              }

              if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.cmUndtkCustCmpxSVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.cmUndtkCustCmpxSVO = responseList
              }

              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.cmUndtkCustCmpxSVO.length

              if (responseList.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }
            } else {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            confirm.dispatch('ADD', response?.msgComm?.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_LoadingData
     * 설명       : 인피니트 로딩데이터
     ******************************************************************************/
    fn_LoadingData () {
      if (!this.isLoading) {
        // 더보기 시 전체체크 해제되어있으면 전체체크 해제 후 조회
        if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
          this.isOneOrMoreCheck = false

          this.fn_CheckAll()
        }

        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')

        this.fn_GetClmnyUndtkCustList()
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      this.$nextTick(() => {
        if (this.cmUndtkCustCmpxSVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in this.cmUndtkCustCmpxSVO) {
            if (this.isOneOrMoreCheck && this.cmUndtkCustCmpxSVO[n].chnlCustId.trim() !== '') {
              this.cmUndtkCustCmpxSVO[n].checked = true
            } else {
              this.cmUndtkCustCmpxSVO[n].checked = false
            }
          }
          this.fn_CheckSelectedItemCount()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount (custInfo) {
      // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
    },

    /******************************************************************************
     * Function명 : clickedAccordion
     * 설명       : 아코디언 버튼 클릭 이벤트 핸들러
     ******************************************************************************/
    clickedAccordion (childObj) {
      let ref = childObj.contNo
      childObj.openedDetail = !childObj.openedDetail

      if (childObj.openedDetail === true) {
        this.$refs[ref].expand()
      }else{
        // 선택한 아코디언 닫기
        this.$refs[ref].collapse()
      }

    },

    /******************************************************************************
     * Function명 : fn_GetSxdsNmByRrn
     * 설명       : 주민번호로 성별 구하기
     ******************************************************************************/
    fn_GetSxdsNmByRrn (custInfo) {
      let rtnVal = ''
      let sexDist = custInfo.rrn.substring(6,7)

      if (sexDist === '1' || sexDist === '3' || sexDist === '5' || sexDist === '7') {
        rtnVal = '남'
      } else if (sexDist === '2' || sexDist === '4' || sexDist === '6' || sexDist === '8') {
        rtnVal = '여'
      }

      return rtnVal
    },

    /******************************************************************************
     * Function명 : fn_Btn_C_OnClick
     * 설명       : 고객카드생성 취소 버튼
     ******************************************************************************/
    fn_Btn_C_OnClick () {
      // 고객카드생성 바텀시트 닫기
      this.$refs.bottomSheet_CustInfo.close()
    },

    /******************************************************************************
     * Function명 : fn_Btn_I_OnClick
     * 설명       : 고객카드생성 확인 버튼
     ******************************************************************************/
    fn_Btn_I_OnClick () {
      // 지점장 권한일 경우 고객카드생성 불가
      if (this.isMngr !== true) {
        this.fn_InsCustInfo()
      }
    },

    /******************************************************************************
     * Function명 : fn_InsCustInfo
     * 설명       : 통합고객 정보를 채널고객으로 등록
     ******************************************************************************/
    fn_InsCustInfo () {
      const lv_Vm = this
      const trnstId = 'txTSSCM55I1'
      const auth = 'I'

      let confirm = lv_Vm.getStore('confirm')
      let iCNewCustRegVO = lv_Vm.fn_SET_OCC() // 고객 저장 데이터 셋팅
      let pParams = iCNewCustRegVO

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
        // 서버 데이터 가져오기
          if (response.body && response.body.chnlCustId) {
            // 고객카드 저장 완료 후 바텀시트 닫고
            lv_Vm.$refs.bottomSheet_CustInfo.close()
            // 리스트 재조회
            lv_Vm.fn_Submit()
            // 네임UI 오픈
            let custInfo = {
              chnlCustId: response.body.chnlCustId,
              custNm: ''
            }
            lv_Vm.fn_OpenCustInfoBs(custInfo)
          } else {
            confirm.dispatch('ADD', response?.msgComm?.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_SET_OCC
     * 설명       : 고객 저장 데이터 셋팅
     ******************************************************************************/
    fn_SET_OCC () {
      let param = {}

      param.prcsfCd = 'I'
      param.condWhlCnt = 0
      param.cnsltNo = this.pObjmsg.cnsltNo
      param.custId = this.pObjmsg.custId
      param.custNm = this.pObjmsg.custNm
      param.knb = this.pObjmsg.knb
      param.busnTrtPathCd = '2220' // 업무처리경로코드 (태블릿_사랑온고객관리 : 2220) *** 신태블릿용 추가 검토 필요
      param.trtSystmPathCd = '22' // 처리시스템경로코드(사랑온 고정 구분값 : 22) *** 신태블릿용 추가 검토 필요
      param.custDtlTypCd = this.fn_USR_getNatyGb(this.pObjmsg.knb) // 고객상세유형코드 셋팅

      if (this.pObjmsg.knb.length > -6) {
        param.chnlCustScCd = '01'
        param.ralDob = this.$bizUtil.addBirthFrontYear(this.pObjmsg.knb.substring(0, 6))
      } else {
        param.chnlCustScCd = '02'
        param.ralDob = ' '
      }

      return param
    },

    /******************************************************************************
     * Function명 : fn_USR_getNatyGb
     * 설명       : 고객상세유형코드 셋팅
     ******************************************************************************/
    fn_USR_getNatyGb (knb) {
      var natyGb = '11'
      if (knb.length < 7) {
        return natyGb
      }
      var knbGb = knb.substring(6, 7)

      if (knbGb === '1' || knbGb === '2' || knbGb === '3' || knbGb === '4') {
        natyGb = '11'
      } else if (knbGb === '5' || knbGb === '6' || knbGb === '7' || knbGb === '8') {
        natyGb = '12'
      }
      return natyGb
    },

    /*********************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     *********************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: this.$options.screenId, // 화면ID+
          pOrgData: this.pOrgData322M, // 최근 조직 조회 데이터
          pSearchKeyword: this.inputCustNm, // 고객명
          pIsMngr: this.isMngr, // 지점장 여부
          pUndtkRsnScCdKey: this.undtkRsnScCdKey, // 인수구분
          pStartDate: this.startDate, // 조회시작일
          pEndDate: this.endDate // 조회종료일
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup116)

            this.inputCustNm = pData.rtnData.searchKeyword // 고객명
            this.undtkRsnScCdKey = pData.rtnData.undtkRsnScCdKey // 인수구분
            this.startDate = pData.rtnData.startDate // 조회시작일
            this.endDate = pData.rtnData.endDate // 조회종료일

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData322M = pData.rtnData.pOrgData

            // 조회이력 저장
            this.isSearched = true

            // 수금인수고객 조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    async fn_OpenCustInfoBs (item) {
      const lv_Vm = this
      let prcContNo = ''
      let nmUiObject = {}

      // 계약번호 복호화 
      if(!this.$bizUtil.isEmpty(item.contNoEnc)) {
        prcContNo = await this.$commonUtil.getDecryptCall(this, [item.contNoEnc]) 
        prcContNo = prcContNo[0]
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, // 채널 고객ID
        custId    : item.custId, // 고객ID
        cnsltNo    : item.cnsltNo, // 컨설턴트 번호
        contNo    : prcContNo, // 계약번호
        parentId  : this.$options.screenId, // 화면ID+
        sxdsCd    : item.sxdsCd, // 성별코드
        sxdsCdNm  : item.sxdsCdNm, // 성별
        knb       : item.rrn, // 주민번호
        knbEnc    : item.rrnEnc, // 주민번호(암호화)
        birthDate : item.dob, // 생년월일
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenNameUiCard(lv_Vm, nmUiObject,(pData)=>{
         //Close 콜백 처리
         if (pData.prcsfCd === 'I') { // 고객카드 생성시
          lv_Vm.fn_SetAsyncChnlCustId(pData)
        }
         return
      })
    },

    /**
     * @description 계피동일 계약건, 채널고객ID 동기화
     * @param       {String}  custTye  계약자/피보험자 구분 값
     * @param       {Object}  custInfo 고객카드 생성된 고객 데이터 
     */
    fn_SetAsyncChnlCustId(custInfo) {
      this.cmUndtkCustCmpxSVO.forEach(lv_Item => {
        
        if (lv_Item.custId === custInfo.custId) {
          lv_Item.chnlCustId = custInfo.chnlCustId
        }
      })

    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData322M = {} // 조직 조회 데이터
                this.inputCustNm = '' // 고객명
                this.undtkRsnScCdKey = '0' // 인수구분
                this.startDate = this.$bizUtil.getFirstDayOfMonth(new Date(moment(this.nowDate).add(-6, 'months')), 'String') // 조회시작일
                this.endDate = this.$bizUtil.getLastDayOfMonth(new Date(moment(new Date()).format('YYYY-MM-DD')), 'String') // 조회종료일

                this.isSearched = false // 검색이력 초기화

                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
    /**
     * @description 보험계약기본사항(MSPPM931M) 화면으로 이동한다.
     *              @type viewId     수신화면, callerId   송신화면, chnlCustId  채널아이디
     *                    cnsltNo    (필수값) 컨설턴트번호, contNo     (필수값) 계약번호
     * @param       {String} contNo     계약번호
     * @param       {String} chnlCustId 채널아이디 
     */
    async fn_MoveMSPPM931M (custInfo) {
      let prcContNo = ''
      
      // 계약번호 복호화 
      if(!this.$bizUtil.isEmpty(custInfo.contNoEnc)) {
        prcContNo = await this.$commonUtil.getDecryptCall(this, [custInfo.contNoEnc]) 
        prcContNo = prcContNo[0]
      }

      let contInfoObj = {
        viewId : 'MSPPM932D', // MSPPM931M - 계약정보 디비젼
        callerId : this.$options.screenId, // 화면ID+
        chnlCustId : custInfo.chnlCustId, // 채널고객ID
        cnsltNo : this.lv_cnsltNo, // 컨설턴트 사번
        contNo  : prcContNo, // 계약번호(복호화)
        contObj : custInfo // 계약정보
      }

      this.$router.push({name: 'MSPPM931M', params: contInfoObj})
    },
    /******************************************************************************
     * Function명 : fn_OnPageScrollEvent
     * 설명       : 고객목록 스크롤 이벤트 메서드
     ******************************************************************************/
    fn_OnPageScrollEvent (e) {
      let target = e.target;      
      if(!target) return
      
      let scrollPosition = target.scrollTop 
      let scrollHeight = e.target.scrollHeight
      let clientHeight = e.target.clientHeight
      this.crntScrollPosition = scrollPosition
      
      // console.log("scrollPosition ="+ scrollPosition +"/ scrollHeight="+scrollHeight + " / clientHeight="+clientHeight)      
    },
  } // ::Methods End
}
</script>
<style>
.maxW240 {
  max-width: 240px !important;
}
</style>